<template>

  <section class="w-full">

    <header-register :showButtonView="true">
      {{ $t("sectionPbm.titlePbm") }}
    </header-register>

    <main class="w-full">

      <ManagePbmCreate />

    </main>

  </section>

</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManagePbmCreate from "@/components/pbm/components/create/ManagePbmCreate.vue";

export default {
  name: "PbmEdit",
  components: {
    HeaderRegister,
    ManagePbmCreate,
  },
};
</script>

